<template>
	<v-col>
		<v-layout justify-center class="page-title"
			><strong>更新密碼 Update password</strong></v-layout
		>
		<v-layout justify-center>
			<v-card class="login-form">
				<v-form ref="form">
					<v-text-field
						outline
						:label="labels.old_pw"
						type="password"
						v-model="user.old_password"
						:rules="requiredRules(labels.old_pw)"
					></v-text-field>
					<v-text-field
						outline
						:label="labels.new_pw"
						type="password"
						v-model="user.new_password"
						:rules="
							requiredRules(labels.new_pw).concat(newPwRules(user.old_password))
						"
					></v-text-field>
					<v-text-field
						outline
						:label="labels.cfm_pw"
						type="password"
						v-model="user.confirm_password"
						:rules="
							requiredRules(labels.cfm_pw).concat(cfmPwRules(user.new_password))
						"
					></v-text-field>
					<v-btn
						class="updt-pw-btn justify-center"
						color="blue"
						@click="updatePassword"
						block
						dark
						>Update password</v-btn
					>
				</v-form>
			</v-card>
		</v-layout>
	</v-col>
</template>

<script>
import EnginerService from '../service/engineer-service';
import LoginService from '../service/login-service';

const engineerService = new EnginerService();
const loginService = new LoginService();

export default {
	name: 'UpdatePassword',
	data: () => ({
		form: null,
		user: {
			old_password: '',
			new_password: '',
			confirm_password: '',
		},
		requiredRules: (field_name) => [(v) => !!v || `${field_name} is required!`],
		newPwRules: (old_pw) => [
			(v) =>
				v !== old_pw ||
				'新密碼不能與舊密碼重覆 New password cannot be same as old password!',
		],
		cfmPwRules: (cfm_pw) => [
			(v) =>
				v === cfm_pw ||
				'新密碼與確認新密碼不符 Confirm New Password does not match!',
		],
		confirmcfmPwRules: [],
		labels: {
			old_pw: '舊密碼 Old Password',
			new_pw: '新密碼 New Password',
			cfm_pw: '確認新密碼 Confirm New Password',
		},
	}),
	methods: {
		updatePassword() {
			const valid_form = this.$refs.form.validate();
			if (valid_form) {
				const payload = {
					password: this.user.new_password,
					old_password: this.user.old_password,
				};
				const engineer_no = loginService.getEngineerNo();
				engineerService
					.updatePw(engineer_no, payload)
					.then((res) => {
						this.promptSnackbarMessage(res.data.success);
						if (res.data.success) {
							this.$router.push('search');
						}
					})
					.catch(() => {
						this.promptSnackbarMessage(false);
					});
			}
		},
		promptSnackbarMessage(success) {
			const color = success ? 'success' : 'error';
			const msg = success
				? 'Password updated successfully!'
				: 'Fail to update password, please try again.';
			this.$emit('snack-bar', {
				color,
				msg,
			});
		},
	},
};
</script>

<style lang="scss">
.updt-pw-title {
	font-size: x-large;
	padding-bottom: 1rem;
}

.updt-pw-btn {
	margin-top: 2rem;
	margin-bottom: 1rem;
	/*display: flex;*/
	align-content: center;
}
</style>
